export type SupportedLocale = 'en' | 'en-GB' | 'de' | 'de-DE' | 'sv' | 'sv-SE' | 'fr' | 'fr-FR';

export const DEFAULT_LOCALE: SupportedLocale = 'en-GB';

export const extractLanguage = (x: string = DEFAULT_LOCALE) => x.split('-')[0];

export const DEFAULT_LANG = extractLanguage(DEFAULT_LOCALE);

export const supportedLocaleMap: Record<SupportedLocale, SupportedLocale> = {
    de: 'de-DE',
    'de-DE': 'de-DE',
    en: 'en-GB',
    'en-GB': 'en-GB',
    sv: 'sv-SE',
    'sv-SE': 'sv-SE',
    fr: 'fr-FR',
    'fr-FR': 'fr-FR',
};

const isSupportedLocale = (x: string): x is SupportedLocale => x in supportedLocaleMap;

export const getSupportedLocale = (preferredLocale: string): SupportedLocale => {
    if (isSupportedLocale(preferredLocale)) {
        return supportedLocaleMap[preferredLocale];
    } else {
        return DEFAULT_LOCALE;
    }
};
