import { extractLanguage } from '../i18n/lang';
import { configureFetchDisplayMessages } from '../i18n/services';
import { configureMockUserManager, configureUserManager, createUserManager } from './login/login';
import { accessToken } from './token-handling/accessToken';
import { trace } from './setup/trace';
import { attemptInitialSignIn } from './setup/oauth';
import { config } from './config';
import { store } from '../redux/store';
import { accessTokenStored, idTokenStored } from './token-handling/token.slice';
import { userProfileObtained, userSessionExpired, userSessionRenewed } from './login/login.slice';
import { UserManager } from 'oidc-client-ts';
import { EVENT_USER_LANGUAGE_CHANGED, EVENT_USER_PROFILE_CHANGED } from '@rio-cloud/rio-user-menu-component';
import { getLocale } from '../i18n/lang.selectors';
import { OAuthConfig } from './OAuthConfig';
import { datadogLogs } from '@datadog/browser-logs';
import { SessionRenewedResult } from './login/oauth.types';

export const main = (renderApp: () => void) => {
    const fetchDisplayMessages = configureFetchDisplayMessages(store);

    // We want the `<html lang>` attribute to be synced with the
    // language currently displayed
    store.subscribe(() => {
        const lang = extractLanguage(getLocale(store.getState()));
        const html = document.querySelector('html');

        if (html && lang && html.getAttribute('lang') !== lang) {
            html.setAttribute('lang', lang);
        }
    });

    const oauthConfig = {
        onSessionExpired: () => {
            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onSessionRenewed: (result: SessionRenewedResult) => {
            trace('index.onTokenRenewed', result);

            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(result.idToken));
            store.dispatch(userProfileObtained(result.profile));

            datadogLogs.setGlobalContextProperty('usr', {
                id: result.profile.sub,
                account: result.profile.account,
            });

            store.dispatch(userSessionRenewed());

            // You will need to get the user language yourself then
            // you may fetch the suitable messages. Depending
            // on when and from where you fetch the user settings you might
            // want to employ a loading spinner while the request is ongoing.
            fetchDisplayMessages(result.locale);
        },
    } as OAuthConfig;

    const userManager: UserManager = config.login.mockAuthorization
        ? configureMockUserManager(oauthConfig)
        : configureUserManager(oauthConfig, createUserManager());

    const signinSilent = userManager.signinSilent.bind(userManager);
    document.addEventListener(EVENT_USER_LANGUAGE_CHANGED, () => signinSilent());
    document.addEventListener(EVENT_USER_PROFILE_CHANGED, () => signinSilent());

    userManager
        .clearStaleState()
        .then(() => attemptInitialSignIn(userManager))
        .then(
            () => renderApp(),
            () => {}
        )
        .catch((error) => {
            console.error('could not start application', error);
        });
};
