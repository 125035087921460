import ErrorState from '@rio-cloud/rio-uikit/ErrorState';
import { useNavigate } from 'react-router-dom';
import { FormattedMessageUpperFirst } from '../application/i18n/FormattedMessageUpperFirst';

const ErrorPage = () => {
    const navigate = useNavigate();
    const emailAddress = `${import.meta.env.VITE_SUPPORT_ADDRESS}`;

    return (
        <ErrorState
            headline={<FormattedMessageUpperFirst id="scm-web.notification.somethingWrong" />}
            message={<FormattedMessageUpperFirst id="scm-web.notification.reloadAndContactSupport" />}
            buttons={[
                {
                    text: <FormattedMessageUpperFirst id="scm-web.action.reloadPage" />,
                    onClick: () => navigate('/'),
                    className: 'btn-primary',
                },
                {
                    text: <FormattedMessageUpperFirst id="scm-web.notification.contactSupport" />,
                    href: `mailto:${emailAddress}`,
                    className: 'btn btn-outline btn-secondary',
                },
            ]}
        />
    );
};

export default ErrorPage;
