import scmServiceApi from '../scmService.api';
import type {
    ClosingPeriodApprovalStatus,
    SupplierClosingPeriod,
    TenantClosingPeriod,
} from '../../../domain/model/closingday/ClosingPeriod.types';
import {
    mapClosingPeriodApprovalStatusToApi,
    toApiClosingPeriodUpdate,
    toSupplierClosingPeriods,
    toTenantClosingPeriods,
} from './closingPeriod.mapper';
import {
    pendingSupplierClosingPeriodsCountsApiTag,
    tenantGlobalClosingPeriodApiTag,
    tenantSupplierClosingPeriodApiTag,
} from './closingPeriod.tags';
import {
    PendingSupplierClosingPeriodsCountsResponseSchema,
    SupplierClosingPeriodResponseSchema,
    TenantClosingPeriodResponseSchema,
} from './closingPeriod.schema';

type GetClosingPeriodsForTenantParams = {
    from: string;
    to: string;
};

type ClosingPeriodId = string;

type PatchSupplierClosingPeriodParams = {
    closingPeriodId: ClosingPeriodId;
    newStatus: Exclude<ClosingPeriodApprovalStatus, 'PENDING'>;
};

export const tenantClosingPeriodEndpoints = scmServiceApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllTenantClosingPeriods: builder.query<TenantClosingPeriod[], GetClosingPeriodsForTenantParams>({
            query: ({ from, to }) => ({
                url: `closing-periods?from=${from}&to=${to}&created_by=tenant`,
            }),
            transformResponse: (x: unknown) => {
                const response = TenantClosingPeriodResponseSchema.parse(x);
                return toTenantClosingPeriods(response);
            },
            providesTags: [tenantGlobalClosingPeriodApiTag],
        }),
        getAllSupplierClosingPeriods: builder.query<SupplierClosingPeriod[], GetClosingPeriodsForTenantParams>({
            query: ({ from, to }) => ({
                url: `closing-periods?from=${from}&to=${to}&created_by=supplier`,
            }),
            transformResponse: (x: unknown) => {
                const response = SupplierClosingPeriodResponseSchema.parse(x);
                return toSupplierClosingPeriods(response);
            },
            providesTags: [tenantSupplierClosingPeriodApiTag],
        }),
        deleteTenantClosingPeriod: builder.mutation<void, ClosingPeriodId>({
            query: (closingPeriodId) => ({
                url: `closing-periods/${closingPeriodId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [tenantGlobalClosingPeriodApiTag],
        }),
        putTenantClosingPeriod: builder.mutation<void, TenantClosingPeriod>({
            query: (closingPeriod) => ({
                url: `closing-periods/${closingPeriod.id}`,
                method: 'PUT',
                body: toApiClosingPeriodUpdate(closingPeriod),
            }),
            invalidatesTags: [tenantGlobalClosingPeriodApiTag],
        }),
        patchSupplierClosingPeriodStatus: builder.mutation<void, PatchSupplierClosingPeriodParams>({
            query: ({ closingPeriodId, newStatus }) => ({
                url: `closing-periods/${closingPeriodId}`,
                method: 'PATCH',
                body: { status: mapClosingPeriodApprovalStatusToApi(newStatus) },
            }),
            invalidatesTags: [tenantSupplierClosingPeriodApiTag, pendingSupplierClosingPeriodsCountsApiTag],
        }),
        getPendingSupplierClosingPeriodsCount: builder.query<number, void>({
            query: () => ({
                url: 'supplier-closing-periods-counts?approval_status=pending',
            }),
            transformResponse: (x: unknown) => {
                const response = PendingSupplierClosingPeriodsCountsResponseSchema.parse(x);
                return response.items[0].count;
            },
            providesTags: [pendingSupplierClosingPeriodsCountsApiTag],
        }),
    }),
});

export const {
    useGetAllTenantClosingPeriodsQuery,
    useGetAllSupplierClosingPeriodsQuery,
    useDeleteTenantClosingPeriodMutation,
    usePutTenantClosingPeriodMutation,
    usePatchSupplierClosingPeriodStatusMutation,
    useGetPendingSupplierClosingPeriodsCountQuery,
} = tenantClosingPeriodEndpoints;
