import { z } from 'zod';
import { makeListSchema } from '../common/list.schema';

export const AddressSchema = z.object({
    name: z.string(),
    street: z.string(),
    city: z.string(),
    postal_code: z.string(),
    region_code: z.string().optional(),
    country_code: z.string(),
});

export const ShipToSchema = z.object({
    id: z.string(),
    address: AddressSchema,
});

export const PlaceOfDischargeSchema = z.object({
    id: z.string(),
});

export const StoragePlaceSchema = z.object({
    id: z.string(),
});

export const ShipFromSchema = z.object({
    id: z.string(),
    address: AddressSchema,
});

export const LoadingLocationSchema = z.object({
    internal_id: z.string(),
    ship_from: ShipFromSchema,
});

export const UnloadingLocationSchema = z.object({
    internal_id: z.string(),
    ship_to: ShipToSchema,
    place_of_discharge: PlaceOfDischargeSchema,
    storage_place: StoragePlaceSchema.optional(),
});

export const UnloadingLocationWithoutStoragePlaceSchema = UnloadingLocationSchema.omit({
    storage_place: true,
});

export const LocationsResponseSchema = z.object({
    loading_locations: LoadingLocationSchema.array(),
    unloading_locations: UnloadingLocationSchema.array(),
});

export const LoadingLocationsResponseSchema = makeListSchema(LoadingLocationSchema);

export const UnloadingLocationsWithoutStoragePlaceResponseSchema = z.object({
    items: UnloadingLocationWithoutStoragePlaceSchema.array(),
});
