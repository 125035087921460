import { z } from 'zod';
import {
    ClosingPeriodApprovalStatusSchema,
    ClosingPeriodTypeSchema,
    IsClosingDayResponseSchema,
    SupplierClosingPeriodResponseSchema,
    SupplierClosingPeriodSchema,
    TenantClosingPeriodResponseSchema,
    TenantClosingPeriodSchema,
    TenantOrSupplierClosingPeriodResponseSchema,
    TenantOrSupplierClosingPeriodSchema,
} from './closingPeriod.schema';
import {
    ClosingPeriod,
    ClosingPeriodApprovalStatus,
    ClosingPeriodType,
    SupplierClosingPeriod,
    TenantClosingPeriod,
} from '../../../domain/model/closingday/ClosingPeriod.types';
import { CountryCode, RegionCode } from '../../../domain/model/location/Location.types';
import { toLoadingLocation } from '../location/location.mapper';

export type ApiClosingPeriodForTenant = z.infer<typeof TenantClosingPeriodSchema>;
export type ApiClosingPeriodForSupplier = z.infer<typeof SupplierClosingPeriodSchema>;
export type ApiClosingPeriod = z.infer<typeof TenantOrSupplierClosingPeriodSchema>;
export type ApiClosingPeriodForTenantResponseSchema = z.infer<typeof TenantClosingPeriodResponseSchema>;
export type ApiSupplierClosingPeriodResponseSchema = z.infer<typeof SupplierClosingPeriodResponseSchema>;
export type ApiClosingPeriodForSupplierResponseSchema = z.infer<typeof TenantOrSupplierClosingPeriodResponseSchema>;
export type ApiClosingPeriodApprovalStatus = z.infer<typeof ClosingPeriodApprovalStatusSchema>;
export type ApiClosingPeriodType = z.infer<typeof ClosingPeriodTypeSchema>;
export type ApiIsClosingDay = z.infer<typeof IsClosingDayResponseSchema>;

const mapClosingDayTypeToDomain = (apiStatus: ApiClosingPeriodType): ClosingPeriodType => {
    switch (apiStatus) {
        case 'public_holiday':
            return 'PUBLIC_HOLIDAY';
        case 'weekend':
            return 'WEEKEND';
    }
};

const mapClosingDayTypeToApi = (status: ClosingPeriodType): ApiClosingPeriodType => {
    switch (status) {
        case 'PUBLIC_HOLIDAY':
            return 'public_holiday';
        case 'WEEKEND':
            return 'weekend';
    }
};

const toTenantClosingPeriod = (input: ApiClosingPeriodForTenant): TenantClosingPeriod => ({
    id: input.id,
    dateRange: {
        startAt: input.start_date_at,
        endAt: input.end_date_at,
    },
    type: mapClosingDayTypeToDomain(input.type),
    validForCountry: input.valid_for_country as CountryCode,
    restrictedToRegions: input.restricted_to_regions as RegionCode[],
    description: input.description,
});

export const toTenantClosingPeriods = (input: ApiClosingPeriodForTenantResponseSchema): TenantClosingPeriod[] =>
    input.items.map(toTenantClosingPeriod);

const toSupplierClosingPeriod = (input: ApiClosingPeriodForSupplier): SupplierClosingPeriod => ({
    id: input.id,
    supplierId: input.supplier_id,
    dateRange: {
        startAt: input.start_date_at,
        endAt: input.end_date_at,
    },
    description: input.description,
    status: mapClosingPeriodApprovalStatusToDomain(input.status),
    relevantForLoadingLocations: input.relevant_for_loading_locations.map(toLoadingLocation),
    requestedAt: input.requested_at,
});

export const toSupplierClosingPeriods = (input: ApiSupplierClosingPeriodResponseSchema): SupplierClosingPeriod[] =>
    input.items.map(toSupplierClosingPeriod);

export const mapClosingPeriodApprovalStatusToDomain = (
    apiStatus: ApiClosingPeriodApprovalStatus
): ClosingPeriodApprovalStatus => {
    switch (apiStatus) {
        case 'approved':
            return 'APPROVED';
        case 'pending':
            return 'PENDING';
        case 'rejected':
            return 'REJECTED';
    }
};

export const mapClosingPeriodApprovalStatusToApi = (
    status: ClosingPeriodApprovalStatus
): ApiClosingPeriodApprovalStatus => {
    switch (status) {
        case 'APPROVED':
            return 'approved';
        case 'PENDING':
            return 'pending';
        case 'REJECTED':
            return 'rejected';
    }
};

const isSupplierClosingPeriod = (input: ApiClosingPeriod): input is ApiClosingPeriodForSupplier => {
    return 'relevant_for_loading_locations' in input;
};
export const toClosingPeriod = (input: ApiClosingPeriod): ClosingPeriod => {
    if (isSupplierClosingPeriod(input)) {
        return toSupplierClosingPeriod(input);
    } else {
        return toTenantClosingPeriod(input);
    }
};

export const toClosingPeriodsForSupplier = (input: ApiClosingPeriodForSupplierResponseSchema): ClosingPeriod[] =>
    input.items.map(toClosingPeriod);

export const toApiClosingPeriodUpdate = (input: TenantClosingPeriod): ApiClosingPeriodForTenant => ({
    id: input.id,
    start_date_at: input.dateRange.startAt,
    end_date_at: input.dateRange.endAt,
    type: mapClosingDayTypeToApi(input.type),
    valid_for_country: input.validForCountry,
    restricted_to_regions: input.restrictedToRegions,
    description: input.description,
});

export const toIsClosingDay = (input: ApiIsClosingDay): boolean => input.is_closing_day;
