import {
    PendingSupplierClosingPeriodsCountApiTagType,
    SupplierClosingDayApiTagType,
    TenantGlobalClosingDayApiTagType,
    TenantSupplierClosingDayApiTagType,
} from '../scmService.api';
import { RTKLikeTag } from '../../../application/redux/toolkit/RTKQLike.types';

export const tenantGlobalClosingPeriodApiTag: RTKLikeTag = { type: TenantGlobalClosingDayApiTagType, id: 'LIST' };
export const tenantSupplierClosingPeriodApiTag: RTKLikeTag = { type: TenantSupplierClosingDayApiTagType, id: 'LIST' };
export const supplierClosingPeriodApiTag: RTKLikeTag = { type: SupplierClosingDayApiTagType, id: 'LIST' };

export const pendingSupplierClosingPeriodsCountsApiTag: RTKLikeTag = {
    type: PendingSupplierClosingPeriodsCountApiTagType,
    id: 'LIST',
};
