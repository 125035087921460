import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { displayMessagesEn } from './displayMessages';
import { DEFAULT_LOCALE, getSupportedLocale, SupportedLocale } from './lang';
import { TranslationKeys } from './translationKeys.types';

export type DisplayMessages = Record<TranslationKeys, string>;

interface CombinedMessages {
    [key: string]: DisplayMessages;
}

export interface LangState {
    allMessages: CombinedMessages;
    displayMessages: DisplayMessages | undefined;
    displayLocale: SupportedLocale | undefined;
}

interface MessagesPayload {
    locale: SupportedLocale;
    displayMessages: DisplayMessages;
}

const defaultMessages = {
    [DEFAULT_LOCALE]: displayMessagesEn,
};

const initialState: LangState = {
    allMessages: defaultMessages,
    displayMessages: undefined,
    displayLocale: undefined,
};

const langSlice = createSlice({
    name: 'lang',
    initialState,
    reducers: {
        localeChanged: (state, action: PayloadAction<string>) => {
            const preferredLocale = action.payload;
            const displayLocale = getSupportedLocale(preferredLocale);

            state.displayLocale = displayLocale;
            state.displayMessages = state.allMessages[displayLocale];
        },
        displayMessagesFetched: (state, action: PayloadAction<MessagesPayload>) => {
            const { locale, displayMessages } = action.payload;

            state.allMessages = {
                ...state.allMessages,
                [locale]: displayMessages,
            };
            state.displayMessages = displayMessages;
            state.displayLocale = locale;
        },
    },
});

export const { localeChanged, displayMessagesFetched } = langSlice.actions;

export default langSlice.reducer;
