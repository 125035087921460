import React, { ErrorInfo, PropsWithChildren, ReactNode } from 'react';

export class ErrorBoundary extends React.Component<PropsWithChildren<{ fallback: ReactNode }>, { hasError: boolean }> {
    constructor(props: PropsWithChildren<{ fallback: ReactNode }>) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: unknown) {
        return { hasError: true };
    }

    componentDidCatch(error: unknown, errorInfo: ErrorInfo) {
        console.error('Unexpected error in boundary:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // eslint-disable-next-line react/prop-types
            return this.props.fallback;
        }
        // eslint-disable-next-line react/prop-types
        return this.props.children;
    }
}
