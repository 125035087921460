import {
    LoadingLocation,
    Locations,
    UnloadingLocationWithoutStoragePlace,
} from '../../../domain/model/location/Location.types';
import {
    LoadingLocationsResponseSchema,
    LocationsResponseSchema,
    UnloadingLocationsWithoutStoragePlaceResponseSchema,
} from './location.schema';
import { toLoadingLocations, toLocations, toUnloadingLocationsWithoutStoragePlace } from './location.mapper';
import scmServiceApi from '../scmService.api';

type GetLocationsParams = {
    supplierId: string;
};

export const locationEndpoints = scmServiceApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllLocations: builder.query<Locations, GetLocationsParams>({
            query: ({ supplierId }) => ({
                url: `suppliers/${supplierId}/locations`,
            }),
            transformResponse: (x: unknown) => {
                const locationsResponse = LocationsResponseSchema.parse(x);
                return toLocations(locationsResponse);
            },
        }),
        getAllLoadingLocations: builder.query<LoadingLocation[], GetLocationsParams>({
            query: ({ supplierId }) => ({
                url: `/loading-locations?filterBySupplierId=${supplierId}`,
            }),
            transformResponse: (x: unknown) => {
                const locationsResponse = LoadingLocationsResponseSchema.parse(x);
                return toLoadingLocations(locationsResponse);
            },
        }),
        getUnloadingLocationsWithoutStoragePlace: builder.query<UnloadingLocationWithoutStoragePlace[], void>({
            query: () => ({
                url: 'unloading-locations?without_storage_place',
            }),
            transformResponse: (x: unknown) => {
                const response = UnloadingLocationsWithoutStoragePlaceResponseSchema.parse(x);
                return toUnloadingLocationsWithoutStoragePlace(response);
            },
        }),
    }),
});

export const {
    useGetAllLocationsQuery,
    useGetAllLoadingLocationsQuery,
    useLazyGetAllLocationsQuery,
    useGetUnloadingLocationsWithoutStoragePlaceQuery,
} = locationEndpoints;
