import { datadogLogs } from '@datadog/browser-logs';
import cloneDeepWith from 'lodash/cloneDeepWith';

const tokenStringReplacer = (val: string) => {
    if (val && typeof val.replace === 'function') {
        val = val.replace(/access_token=[^&]*/, 'access_token=REDACTED');
        val = val.replace(/id_token=[^&]*/, 'id_token=REDACTED');
        return val;
    }
};

if (import.meta.env.PROD) {
    datadogLogs.init({
        clientToken: 'pub14d4e015627562f8e1cb4f3b19918a37',
        forwardErrorsToLogs: false,
        forwardConsoleLogs: ['error'],
        site: 'datadoghq.eu',
        sessionSampleRate: 100,
        version: APP_VERSION,
        service: 'scm-scania-frontend',
        env: 'prod',
        beforeSend: (obj: object) => cloneDeepWith(obj, tokenStringReplacer),
    });
    datadogLogs.setGlobalContextProperty('account_id', '940091221404');
    datadogLogs.setGlobalContextProperty('team', 'rio-team-logistics-inbound-red');
    datadogLogs.setGlobalContextProperty('team_identifier', 'rio-team-logistics-inbound-red');
}
