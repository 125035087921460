import { SupportedLocale } from './lang';
import displayMessagesEn from './translations/en-GB.json';

export { displayMessagesEn };

const displayMessageImports = {
    'de-DE': () => import('./translations/de-DE.json'),
    'sv-SE': () => import('./translations/sv-SE.json'),
    'fr-FR': () => import('./translations/zh-CN.json'),
};

type SupportedDisplayMessageLocale = keyof typeof displayMessageImports;

const isSupportedDisplayMessageLocale = (locale: SupportedLocale): locale is SupportedDisplayMessageLocale =>
    Object.hasOwn(displayMessageImports, locale);

export const importDisplayMessages = async (locale: SupportedLocale) => {
    if (isSupportedDisplayMessageLocale(locale)) {
        const doImport = displayMessageImports[locale];
        return (await doImport()).default;
    } else {
        return displayMessagesEn;
    }
};
