import { z } from 'zod';

const localDateRegex = /^\d{4}-\d{2}-\d{2}$/;
const localDateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?$/;

export const LocalDateSchema = z.string().regex(localDateRegex, 'Invalid date format');
export const LocalDateTimeSchema = z.string().regex(localDateTimeRegex, 'Invalid date time format');

const LocalDateObjectSchema = z.object({
    local_date_at: LocalDateSchema,
});

const LocalDateTimeObjectSchema = z.object({
    local_date_time_at: LocalDateTimeSchema,
});

export const DateWithOptionalTimeSchema = z.union([LocalDateObjectSchema, LocalDateTimeObjectSchema]);

export const DateTimeIntervalSchema = z.object({
    start_at: z.string().datetime(),
    end_at: z.string().datetime(),
});
