import { SupportedLocale } from './lang';
import { trace } from '../configuration/setup/trace';

// Vite currently does not support glob imports inside node modules
// see https://github.com/vitejs/vite/issues/14102
// => we hard-code all country-code based imports here

// PLUS moment doesn't provide types for the locales, sadly. => We need the ts-expect-error comments

const momentLocaleImports = {
    // @ts-expect-error TS2307
    de: () => import('moment/dist/locale/de'),
    // @ts-expect-error TS2307
    en: () => import('moment/dist/locale/en-gb'),
    // @ts-expect-error TS2307
    sv: () => import('moment/dist/locale/sv'),
    // @ts-expect-error TS2307
    fr: () => import('moment/dist/locale/zh-cn'),
};

type SupportedMomentLocale = keyof typeof momentLocaleImports;

const isSupportedMomentLocale = (localeCode: string): localeCode is SupportedMomentLocale =>
    Object.hasOwn(momentLocaleImports, localeCode);

export const importMomentLocale = async (displayLocale: SupportedLocale) => {
    const localeCode = displayLocale.split('-')[0];

    if (isSupportedMomentLocale(localeCode)) {
        const doImport = momentLocaleImports[localeCode];
        await doImport();
        trace(`Moment locales loaded for "${localeCode}"`);
    }
};
