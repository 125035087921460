import {
    AddressSchema,
    LoadingLocationSchema,
    LoadingLocationsResponseSchema,
    LocationsResponseSchema,
    PlaceOfDischargeSchema,
    ShipFromSchema,
    ShipToSchema,
    StoragePlaceSchema,
    UnloadingLocationSchema,
    UnloadingLocationsWithoutStoragePlaceResponseSchema,
    UnloadingLocationWithoutStoragePlaceSchema,
} from './location.schema';
import { z } from 'zod';
import {
    Address,
    CountryCode,
    LoadingLocation,
    Location,
    Locations,
    LocationWithoutAddress,
    RegionCode,
    UnloadingLocation,
    UnloadingLocationWithoutStoragePlace,
} from '../../../domain/model/location/Location.types';

export type ApiShipTo = z.infer<typeof ShipToSchema>;
export type ApiPlaceOfDischarge = z.infer<typeof PlaceOfDischargeSchema>;
export type ApiStoragePlace = z.infer<typeof StoragePlaceSchema>;
export type ApiShipFrom = z.infer<typeof ShipFromSchema>;
export type ApiLoadingLocation = z.infer<typeof LoadingLocationSchema>;
export type ApiUnloadingLocation = z.infer<typeof UnloadingLocationSchema>;
type ApiUnloadingLocationWithoutStoragePlace = z.infer<typeof UnloadingLocationWithoutStoragePlaceSchema>;
export type ApiUnloadingLocationsWithoutStoragePlaceResponse = z.infer<
    typeof UnloadingLocationsWithoutStoragePlaceResponseSchema
>;
type ApiAddress = z.infer<typeof AddressSchema>;
export type ApiLocationsResponse = z.infer<typeof LocationsResponseSchema>;
export type ApiLoadingLocationsResponse = z.infer<typeof LoadingLocationsResponseSchema>;

const toAddress = (input: ApiAddress): Address => ({
    name: input.name,
    street: input.street,
    city: input.city,
    postalCode: input.postal_code,
    regionCode: input.region_code as RegionCode,
    countryCode: input.country_code as CountryCode,
});

export const toLocation = (input: ApiShipTo | ApiShipFrom): Location => ({
    id: input.id,
    address: toAddress(input.address),
});

export const toLocationWithoutAddress = (input: ApiPlaceOfDischarge | ApiStoragePlace): LocationWithoutAddress => ({
    id: input.id,
});

export const toApiAddress = (input: Address): ApiAddress => ({
    name: input.name,
    street: input.street,
    city: input.city,
    postal_code: input.postalCode,
    region_code: input.regionCode,
    country_code: input.countryCode,
});

export const toLoadingLocation = (input: ApiLoadingLocation): LoadingLocation => ({
    internalId: input.internal_id,
    shipFrom: toLocation(input.ship_from),
});

export const toUnloadingLocation = (input: ApiUnloadingLocation): UnloadingLocation => ({
    internalId: input.internal_id,
    shipTo: toLocation(input.ship_to),
    placeOfDischarge: toLocationWithoutAddress(input.place_of_discharge),
    storagePlace: input.storage_place && toLocationWithoutAddress(input.storage_place),
});

export const toLocations = (input: ApiLocationsResponse): Locations => ({
    loadingLocations: input.loading_locations.map(toLoadingLocation),
    unloadingLocations: input.unloading_locations.map(toUnloadingLocation),
});

export const toLoadingLocations = (input: ApiLoadingLocationsResponse) => input.items.map(toLoadingLocation);

export const toUnloadingLocationWithoutStoragePlace = (
    input: ApiUnloadingLocationWithoutStoragePlace
): UnloadingLocationWithoutStoragePlace => ({
    internalId: input.internal_id,
    shipTo: toLocation(input.ship_to),
    placeOfDischarge: toLocationWithoutAddress(input.place_of_discharge),
});

export const toUnloadingLocationsWithoutStoragePlace = (input: ApiUnloadingLocationsWithoutStoragePlaceResponse) =>
    input.items.map(toUnloadingLocationWithoutStoragePlace);
