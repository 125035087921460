import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Interval } from '../../../domain/model/common/Interval.types';

export type BookingState = {
    selectedShipToId: string | undefined;
    selectedPickupTime: string | undefined;
    selectedCutoffTimeWindow: Partial<Interval>;
    createPackagingForUnspecifiedLoadModalOpenForTransportDemandId: string | undefined;
    createPackagingForSpecifiedLoadModalOpenForTransportDemandId: string | undefined;
    editArticleMasterDataModalOpenForArticleNumber: string | undefined;
    editPackagingConfigurationForPackagingId: string | undefined;
    selectedTransportDemandBundleId: string | undefined;
};

const initialState: BookingState = {
    selectedShipToId: undefined,
    selectedPickupTime: undefined,
    selectedCutoffTimeWindow: {},
    createPackagingForUnspecifiedLoadModalOpenForTransportDemandId: undefined,
    createPackagingForSpecifiedLoadModalOpenForTransportDemandId: undefined,
    editArticleMasterDataModalOpenForArticleNumber: undefined,
    editPackagingConfigurationForPackagingId: undefined,
    selectedTransportDemandBundleId: undefined,
};

const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        shipToIdSelected: (state, action: PayloadAction<string | undefined>) => {
            state.selectedShipToId = action.payload;
        },
        pickupTimeSelected: (state, action: PayloadAction<string | undefined>) => {
            state.selectedPickupTime = action.payload;
        },
        cutoffTimeSelected: (state, action: PayloadAction<Partial<Interval>>) => {
            state.selectedCutoffTimeWindow = action.payload;
        },
        editingPackagingOfUnspecifiedLoadForTransportDemandIdStarted: (state, action: PayloadAction<string>) => {
            state.createPackagingForUnspecifiedLoadModalOpenForTransportDemandId = action.payload;
        },
        editingPackagingOfUnspecifiedLoadFinished: (state) => {
            state.createPackagingForUnspecifiedLoadModalOpenForTransportDemandId = undefined;
        },
        editingPackagingOfSpecifiedLoadForTransportDemandIdStarted: (state, action: PayloadAction<string>) => {
            state.createPackagingForSpecifiedLoadModalOpenForTransportDemandId = action.payload;
        },
        editingPackagingOfSpecifiedLoadFinished: (state) => {
            state.createPackagingForSpecifiedLoadModalOpenForTransportDemandId = undefined;
        },
        editingPackagingConfigurationForPackagingIdStarted: (state, action: PayloadAction<string>) => {
            state.editPackagingConfigurationForPackagingId = action.payload;
        },
        editingPackagingConfigurationForPackagingIdFinished: (state) => {
            state.editPackagingConfigurationForPackagingId = undefined;
        },
        editingArticleMasterDataForArticleNumberStarted: (state, action: PayloadAction<string>) => {
            state.editArticleMasterDataModalOpenForArticleNumber = action.payload;
        },
        editingArticleMasterDataForArticleNumberFinished: (state) => {
            state.editArticleMasterDataModalOpenForArticleNumber = undefined;
        },
        transportDetailsViewClosed: (state) => {
            state.createPackagingForUnspecifiedLoadModalOpenForTransportDemandId = undefined;
            state.createPackagingForSpecifiedLoadModalOpenForTransportDemandId = undefined;
        },
        transportDemandBundleIdSelected: (state, { payload }: PayloadAction<string | undefined>) => {
            state.selectedTransportDemandBundleId = payload;
        },
    },
});

export const bookingActions = bookingSlice.actions;

export default bookingSlice;
