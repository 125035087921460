import React, { createContext, type JSX, type PropsWithChildren, type RefObject, useContext, useRef } from 'react';
import { createPortal } from 'react-dom';

type PortalContextType = {
    subNavigation: RefObject<HTMLElement>;
    sidebar: RefObject<HTMLElement>;
};

const PortalContext = createContext({} as PortalContextType);

type ChildrenOnlyProps = PropsWithChildren<{}>;
type ElementOnlyProps = { element: JSX.Element };

/**
 * Used to set up the place where the portal content shall be rendered and a context provider for accessing the portals.
 *
 * Note that there is only ONE portal context throughout the whole app!
 */
export const usePortalDestinations = () => {
    const subNavigation = useRef(null);
    const sidebar = useRef(null);
    const contextValue = useRef<PortalContextType>({ subNavigation, sidebar });

    /** Provides the portal ref as context to its children. */
    const PortalContextProvider = useRef(({ children }: ChildrenOnlyProps) => (
        <PortalContext.Provider value={contextValue.current}>{children}</PortalContext.Provider>
    )).current;

    /** Injects subNavigation ref into the given element. */
    const SubNavigationPortalDestination = useRef(({ element }: ElementOnlyProps) => ({
        ...element,
        ref: subNavigation,
    })).current;

    /** Injects sidebar ref into the given element. */
    const SidebarPortalDestination = useRef(({ element }: ElementOnlyProps) => ({
        ...element,
        ref: sidebar,
    })).current;

    return { PortalContextProvider, SubNavigationPortalDestination, SidebarPortalDestination };
};

/**
 * The returned component renders its children into the "subNavigation" portal created by {@link usePortalDestinations}.
 */
export const useSubNavigationPortal = () => {
    const subNavigationPortalRef = useRef(({ children }: ChildrenOnlyProps) => {
        const context = useContext(PortalContext);
        return context.subNavigation?.current && createPortal(children, context.subNavigation.current);
    });

    return subNavigationPortalRef.current;
};

/**
 * The returned component  renders its children into the "sidebar" portal created by {@link usePortalDestinations}.
 */
export const useSidebarPortal = () => {
    const sidebarPortalRef = useRef(({ children }: ChildrenOnlyProps) => {
        const context = useContext(PortalContext);
        return context.sidebar?.current && createPortal(children, context.sidebar.current);
    });

    return sidebarPortalRef.current;
};
