import { IntlProvider } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import { DEFAULT_LOCALE } from '../../application/i18n/lang';
import { useAppDispatch, useAppSelector } from '../../application/configuration/setup/hooks';
import { hideSessionExpiredDialog } from '../../application/redux/app/app.slice';
import AppHeader from './AppHeader';
import { getSessionExpiredAcknowledged } from '../../application/redux/app/app.selectors';
import { isUserSessionExpired } from '../../application/configuration/login/login.selectors';
import { getDisplayMessages, getLocale } from '../../application/i18n/lang.selectors';
import { usePortalDestinations } from '../usePortal';
import ApplicationLayoutBodyNavigation from '@rio-cloud/rio-uikit/ApplicationLayoutBodyNavigation';

const AppLayout = () => {
    const dispatch = useAppDispatch();

    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);
    const isSessionExpired = useAppSelector(isUserSessionExpired);
    const sessionExpiredAcknowledged = useAppSelector(getSessionExpiredAcknowledged);

    const { PortalContextProvider, SubNavigationPortalDestination, SidebarPortalDestination } = usePortalDestinations();

    if (!(displayMessages && userLocale)) {
        return null;
    }

    const handleSessionExpiredDialogClose = () => dispatch(hideSessionExpiredDialog());
    const showSessionExpired = isSessionExpired && !sessionExpiredAcknowledged;

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} locale={userLocale} messages={displayMessages}>
            <ApplicationLayout>
                <ApplicationLayout.Header>
                    <AppHeader />
                </ApplicationLayout.Header>

                <SidebarPortalDestination element={<ApplicationLayout.Sidebar className="right" />} />

                <ApplicationLayout.Body
                    navigation={<SubNavigationPortalDestination element={<ApplicationLayoutBodyNavigation />} />}
                >
                    <NotificationsContainer />

                    <SessionExpiredDialog
                        locale={userLocale}
                        onClose={handleSessionExpiredDialogClose}
                        show={showSessionExpired}
                    />

                    <PortalContextProvider>
                        <Outlet />
                    </PortalContextProvider>
                </ApplicationLayout.Body>
            </ApplicationLayout>
        </IntlProvider>
    );
};

export default AppLayout;
