import omit from 'lodash/fp/omit';
import { UserProfile as Profile } from 'oidc-client-ts';
import { UserProfile } from './login.slice';

const stripSnakeProps = omit(['family_name', 'given_name']);

export const mapUserProfile = (profile: Profile): UserProfile => ({
    sub: profile.sub,
    azp: profile.azp,
    givenName: profile.given_name,
    familyName: profile.family_name,
    name: profile.name,
    locale: profile.locale,
    email: profile.email,
    ...stripSnakeProps(profile),
});
