import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IntervalWithLocalOffset } from '../../../domain/model/common/Interval.types';

export type TransportState = {
    selectedPickupTimeWindow: IntervalWithLocalOffset | undefined;
};

const initialState: TransportState = {
    selectedPickupTimeWindow: undefined,
};

const transportSlice = createSlice({
    name: 'transports',
    initialState,
    reducers: {
        pickupTimeSelected: (state, action: PayloadAction<IntervalWithLocalOffset>) => {
            state.selectedPickupTimeWindow = action.payload;
        },
    },
});

export const transportActions = transportSlice.actions;

export default transportSlice;
