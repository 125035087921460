import { generatePath } from 'react-router-dom';

export const routes = {
    DEFAULT: '/',
    ERROR: '/error',
    SUPPLIER_BASE: '/supplier',
    SUPPLIER_WITH_ID: '/supplier/:supplierId',
    DEFAULT_FEATURE_SUPPLIER: 'bookings',
    BOOKINGS: '/supplier/:supplierId/bookings',
    BATCH_FLOW_TRANSPORT_DETAILS: '/supplier/:supplierId/bookings/batch-flow-details',
    SEQUENCE_FLOW_TRANSPORT_DETAILS: '/supplier/:supplierId/bookings/sequence-flow-details',
    SEQUENCE_FLOW_ADMINISTRATION: '/administration/sequence-flows',
    TRANSPORTS: '/supplier/:supplierId/transports',
    MATERIAL_PLANNER_CLOSING_DAY_ADMINISTRATION: '/administration/closing-days',
    SUPPLIER_CLOSING_DAY_ADMINISTRATION: '/supplier/:supplierId/administration/closing-days',
};

export const toBookingView = (supplierId: string) => generatePath(routes.BOOKINGS, { supplierId });
export const toTransportView = (supplierId: string) => generatePath(routes.TRANSPORTS, { supplierId });
export const toSupplierClosingDayView = (supplierId: string) =>
    generatePath(routes.SUPPLIER_CLOSING_DAY_ADMINISTRATION, { supplierId });

export const toBatchFlowBookingDetailsView = (
    supplierId: string,
    shipToId: string,
    pickupTime: string,
    cutoffTimeStartAt: string | undefined = undefined,
    cutoffTimeEndAt: string | undefined = undefined
) => {
    const encodedId = encodeURIComponent(shipToId);
    const encodedPickupTime = encodeURIComponent(pickupTime);
    const cutoffTimeStartAtPathParam = cutoffTimeStartAt
        ? `&cutoffTimeStartAt=${encodeURIComponent(cutoffTimeStartAt)}`
        : '';
    const cutoffTimeEndAtPathParam = cutoffTimeEndAt ? `&cutoffTimeEndAt=${encodeURIComponent(cutoffTimeEndAt)}` : '';

    return (
        `${toBookingView(supplierId)}/batch-flow-details?shipToId=${encodedId}` +
        `&pickupTime=${encodedPickupTime}` +
        cutoffTimeStartAtPathParam +
        cutoffTimeEndAtPathParam
    );
};

export const toSequenceFlowBookingDetailsView = (
    supplierId: string,
    transportDemandBundleId: string,
    cutoffTimeStartAt: string | undefined = undefined,
    cutoffTimeEndAt: string | undefined = undefined
) => {
    const encodedId = encodeURIComponent(transportDemandBundleId);
    const cutoffTimeStartAtPathParam = cutoffTimeStartAt
        ? `&cutoffTimeStartAt=${encodeURIComponent(cutoffTimeStartAt)}`
        : '';
    const cutoffTimeEndAtPathParam = cutoffTimeEndAt ? `&cutoffTimeEndAt=${encodeURIComponent(cutoffTimeEndAt)}` : '';

    return (
        `${toBookingView(supplierId)}/sequence-flow-details?transportDemandBundleId=${encodedId}` +
        cutoffTimeStartAtPathParam +
        cutoffTimeEndAtPathParam
    );
};
