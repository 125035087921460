import { FormattedMessage } from 'react-intl';
import type { Props as FormatMessageProps } from 'react-intl/src/components/message';
import type { ReactNode } from 'react';
import omit from 'lodash/omit';

export type FormattedMessageUpperFirstProps = FormatMessageProps & {
    postfix?: ReactNode;
    className?: string;
};

export const FormattedMessageUpperFirst = (props: FormattedMessageUpperFirstProps) => {
    const { postfix, className } = props;
    const formattedMessageProps = omit(props, ['postfix', 'className']);

    return (
        <span className={`text-capitalize-first-word display-inline-block ${className}`}>
            <FormattedMessage {...formattedMessageProps} />
            {postfix}
        </span>
    );
};
