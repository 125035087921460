import React, { useEffect } from 'react';
import { useAllLocations } from '../common/hooks/retrieve-data/locations';
import { LoadingLocation } from '../../domain/model/location/Location.types';
import { useAppDispatch } from '../../application/configuration/setup/hooks';
import { commonActions } from '../../application/redux/common.slice';
import Tag from '@rio-cloud/rio-uikit/Tag';
import TagList from '@rio-cloud/rio-uikit/TagList';
import { useSelectedLoadingLocationRaw } from '../../application/hooks/selectors';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FormattedMessage } from 'react-intl';
import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import { getLoadingLocationLabel } from '../../domain/model/location/loadingLocationFormatter';

const LoadingLocationSelection = () => {
    const { data, isError } = useAllLocations();
    const selectedLoadingLocation = useSelectedLoadingLocationRaw();
    const dispatch = useAppDispatch();

    const selectLoadingLocation = (location: LoadingLocation) => {
        dispatch(commonActions.loadingLocationSelected(location));
    };

    const sortedLoadingLocations = data?.loadingLocations.toSorted((a: LoadingLocation, b: LoadingLocation) =>
        getLoadingLocationLabel(a).localeCompare(getLoadingLocationLabel(b))
    );

    useEffect(() => {
        if (sortedLoadingLocations) {
            if (sortedLoadingLocations.length === 0) {
                Notification.error(<FormattedMessage id="scm-web.notification.somethingWentWrongTryAgain" />);
            }

            if (sortedLoadingLocations.length > 0 && !selectedLoadingLocation) {
                selectLoadingLocation(sortedLoadingLocations[0]);
            }
        }
        if (isError) {
            Notification.error(<FormattedMessage id="scm-web.notification.somethingWentWrongTryAgain" />);
        }
    }, [sortedLoadingLocations, selectedLoadingLocation, isError]);

    if (!sortedLoadingLocations || sortedLoadingLocations?.length <= 1) {
        return null;
    }

    return (
        <ActionBarItem>
            <ActionBarItem.Icon id="site-selection-button">
                <span className="icon rioglyph rioglyph-factory"> </span>
            </ActionBarItem.Icon>
            <ActionBarItem.Popover
                title={<FormattedMessage id="scm-web.domain.loadingLocationSelection" />}
                className="padding-0"
            >
                <TagList inline={false} className="padding-15">
                    {sortedLoadingLocations.map((location: LoadingLocation) => {
                        if (location.internalId === selectedLoadingLocation?.internalId) {
                            return (
                                <Tag active key={location.internalId}>
                                    {getLoadingLocationLabel(location)}
                                </Tag>
                            );
                        } else {
                            return (
                                <Tag
                                    onClick={() => selectLoadingLocation(location)}
                                    clickable
                                    icon="rioglyph-factory"
                                    key={location.internalId}
                                >
                                    {getLoadingLocationLabel(location)}
                                </Tag>
                            );
                        }
                    })}
                </TagList>
            </ActionBarItem.Popover>
        </ActionBarItem>
    );
};

export default LoadingLocationSelection;
