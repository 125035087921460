import { z } from 'zod';
import { makeListSchema } from '../common/list.schema';
import { LocalDateSchema } from '../common/dateTime.schema';
import { LoadingLocationSchema } from '../location/location.schema';

export const ClosingPeriodTypeSchema = z.enum(['public_holiday', 'weekend']);

export const ClosingPeriodApprovalStatusSchema = z.enum(['approved', 'pending', 'rejected']);

export const TenantClosingPeriodSchema = z.object({
    id: z.string(),
    start_date_at: LocalDateSchema,
    end_date_at: LocalDateSchema,
    type: ClosingPeriodTypeSchema,
    valid_for_country: z.string(),
    restricted_to_regions: z.string().array(),
    description: z.string().optional(),
});

export const SupplierClosingPeriodSchema = z.object({
    id: z.string(),
    supplier_id: z.string(),
    start_date_at: LocalDateSchema,
    end_date_at: LocalDateSchema,
    description: z.string(),
    relevant_for_loading_locations: LoadingLocationSchema.array(),
    status: ClosingPeriodApprovalStatusSchema,
    requested_at: z.string().datetime(),
});

export const TenantClosingPeriodResponseSchema = makeListSchema(TenantClosingPeriodSchema);
export const SupplierClosingPeriodResponseSchema = makeListSchema(SupplierClosingPeriodSchema);

export const TenantOrSupplierClosingPeriodSchema = z.union([SupplierClosingPeriodSchema, TenantClosingPeriodSchema]);

export const TenantOrSupplierClosingPeriodResponseSchema = makeListSchema(TenantOrSupplierClosingPeriodSchema);

export const IsClosingDayResponseSchema = z.object({
    is_closing_day: z.boolean(),
});

export const PendingSupplierClosingPeriodsCountsResponseSchema = z.object({
    items: z.tuple([
        z.object({
            status: z.literal('pending'),
            count: z.number(),
        }),
    ]),
});
