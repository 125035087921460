import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserProfile as Profile } from 'oidc-client-ts';
import { AccessToken, accessToken } from './accessToken';

export const TENANT_RIO_EU_PROD = 'rio-eu.prod';
export const TENANT_RIO_EU_TEST = 'rio-eu.test';
export const TENANT_RIO_BRAZIL_PROD = 'rio-brazil.prod';
export const TENANT_RIO_SOUTHKOREA_PROD = 'rio-southkorea.prod';

export interface AccessTokenState {
    accessToken: AccessToken;
    idToken: Profile | null;
}

const initialState: AccessTokenState = {
    accessToken: accessToken.getAccessToken(),
    idToken: null,
};

const tokenSlice = createSlice({
    name: 'tokenHandling',
    initialState,
    reducers: {
        accessTokenStored: (state, action: PayloadAction<AccessToken>) => {
            state.accessToken = action.payload;
        },
        idTokenStored: (state, action: PayloadAction<Profile>) => {
            state.idToken = action.payload;
        },
    },
});

export const { accessTokenStored, idTokenStored } = tokenSlice.actions;

export default tokenSlice.reducer;
