import { routes } from '../../application/routing/routes';
import { FormattedMessageUpperFirst } from '../../application/i18n/FormattedMessageUpperFirst';
import { NavLink } from 'react-router-dom';
import { usePendingSupplierClosingPeriodsCount } from './hooks/retrieve-data/closingPeriodCounts';
import classNames from 'classnames';

type MaterialPlannerClosingPeriodsLinkProps = {
    className?: string;
    onClick?: VoidFunction;
};

const MaterialPlannerClosingPeriodsLink = ({ className, onClick }: MaterialPlannerClosingPeriodsLinkProps) => {
    const { data: pendingClosingPeriods } = usePendingSupplierClosingPeriodsCount();
    const hasBadge = pendingClosingPeriods !== undefined && pendingClosingPeriods > 0;
    const wrapperClass = classNames(className, hasBadge && 'padding-right-20');

    return (
        <NavLink to={routes.MATERIAL_PLANNER_CLOSING_DAY_ADMINISTRATION} className={wrapperClass} onClick={onClick}>
            <FormattedMessageUpperFirst id="scm-web.domain.materialPlannerClosingDays" />
            {hasBadge && (
                <span className="badge badge-warning badge-indicator-pinging position-absolute translate-y-50pct">
                    {pendingClosingPeriods}
                </span>
            )}
        </NavLink>
    );
};

export default MaterialPlannerClosingPeriodsLink;
