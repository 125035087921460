import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from './config';
import { prepareHeaders } from '../../application/configuration/token-handling/prepareHeaders';
import { RTKLikeTagType } from '../../application/redux/toolkit/RTKQLike.types';

export const PackagingApiTagType: RTKLikeTagType = 'Packaging';
export const AtomicPackagingApiTagType: RTKLikeTagType = 'AtomicPackaging';
export const TransportDemandBundlesApiTagType: RTKLikeTagType = 'TransportDemandBundles';
export const SchedulingAgreementApiTagType: RTKLikeTagType = 'SchedulingAgreement';
export const ArticleMasterDataApiTagType: RTKLikeTagType = 'ArticleMasterData';
export const SequenceFlowApiTagType: RTKLikeTagType = 'SequenceFlow';
export const TenantGlobalClosingDayApiTagType: RTKLikeTagType = 'TenantGlobalClosingPeriod';
export const TenantSupplierClosingDayApiTagType: RTKLikeTagType = 'TenantSupplierClosingPeriod';
export const SupplierClosingDayApiTagType: RTKLikeTagType = 'SupplierClosingPeriod';
export const PendingSupplierClosingPeriodsCountApiTagType: RTKLikeTagType = 'PendingSupplierClosingPeriodsCount';

const scmServiceApi = createApi({
    tagTypes: [
        TransportDemandBundlesApiTagType,
        PackagingApiTagType,
        TenantGlobalClosingDayApiTagType,
        TenantSupplierClosingDayApiTagType,
        SupplierClosingDayApiTagType,
        SchedulingAgreementApiTagType,
        ArticleMasterDataApiTagType,
        AtomicPackagingApiTagType,
        SequenceFlowApiTagType,
        PendingSupplierClosingPeriodsCountApiTagType,
    ],
    reducerPath: 'scmServiceApi',
    baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
    endpoints: () => ({}),
});

export default scmServiceApi;
