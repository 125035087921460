import type { Store } from '@reduxjs/toolkit';
import { displayMessagesFetched, localeChanged } from './lang.slice';
import { DEFAULT_LOCALE, getSupportedLocale } from './lang';
import { trace } from '../configuration/setup/trace';
import { importMomentLocale } from './momentLocales';
import { importDisplayMessages } from './displayMessages';

export const configureFetchDisplayMessages = (store: Store) => async (locale: string) => {
    if (!locale) {
        trace('No "locale" supplied when fetching display messages!');
        return Promise.reject();
    }

    const supportedLocale = getSupportedLocale(locale);

    try {
        await importMomentLocale(supportedLocale);

        const displayMessages = await importDisplayMessages(supportedLocale);
        trace(`Display messages fetched for "${supportedLocale}"`);
        store.dispatch(displayMessagesFetched({ locale: supportedLocale, displayMessages }));
    } catch (error) {
        console.error(`Display messages for "${supportedLocale}" could not be fetched.`, error);
        store.dispatch(localeChanged(DEFAULT_LOCALE));
    }
};
