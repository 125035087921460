import { NavLink } from 'react-router-dom';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import ApplicationHeader, { type ModulePropType } from '@rio-cloud/rio-uikit/ApplicationHeader';
import { routes, toBookingView, toSupplierClosingDayView, toTransportView } from '../../application/routing/routes';
import { FormattedMessageUpperFirst } from '../../application/i18n/FormattedMessageUpperFirst';
import { useSupplierIdRaw } from '../../application/hooks/selectors';
import MaterialPlannerClosingPeriodsLink from './MaterialPlannerClosingPeriodsLink';
import LoadingLocationSelection from './LoadingLocationSelection';

type SupplierLinkProps = {
    supplierId: string | undefined;
};

const BookingsLink = ({ supplierId }: SupplierLinkProps) => (
    <NavLink to={supplierId ? toBookingView(supplierId) : routes.SUPPLIER_BASE}>
        <FormattedMessageUpperFirst id="scm-web.domain.booking" />
    </NavLink>
);

const TransportsLink = ({ supplierId }: SupplierLinkProps) => (
    <NavLink to={supplierId ? toTransportView(supplierId) : routes.SUPPLIER_BASE}>
        <FormattedMessageUpperFirst id="scm-web.domain.transport" />
    </NavLink>
);

const SequenceFlowsLink = () => (
    <NavLink to={routes.SEQUENCE_FLOW_ADMINISTRATION}>
        <FormattedMessageUpperFirst id="scm-web.domain.sequenceFlow" />
    </NavLink>
);

const SupplierClosingPeriodsLink = ({ supplierId }: SupplierLinkProps) => (
    <NavLink to={supplierId ? toSupplierClosingDayView(supplierId) : routes.SUPPLIER_BASE}>
        <FormattedMessageUpperFirst id="scm-web.domain.supplierClosingDays" />
    </NavLink>
);

const AppHeader = () => {
    const supplierId = useSupplierIdRaw();

    const navItems: ModulePropType[] = [
        { key: `bookings-${supplierId}`, route: <BookingsLink supplierId={supplierId} /> },
        { key: `transports-${supplierId}`, route: <TransportsLink supplierId={supplierId} /> },
        { key: 'sequence-flows', route: <SequenceFlowsLink /> },
        { key: 'material-planner-closing-days', route: <MaterialPlannerClosingPeriodsLink /> },
        { key: `supplier-closing-days-${supplierId}`, route: <SupplierClosingPeriodsLink supplierId={supplierId} /> },
    ];

    const actionBarItems = [
        <LoadingLocationSelection key="site-selection" />,
        <DefaultUserMenu key="user-menu" environment={import.meta.env.MODE} />,
    ];

    return (
        <ApplicationHeader
            homeRoute={<NavLink to="/" />}
            label={
                <h1 className="text-size-h6 text-size-h4-sm margin-y-5 margin-top-20pct-sm">
                    <FormattedMessageUpperFirst id="scm-web.application.AppHeader.label" />
                </h1>
            }
            navItems={navItems}
            actionBarItems={actionBarItems}
        />
    );
};

export default AppHeader;
